import React, { lazy, Suspense } from 'react';
import Async from "react-async"
import CareerLoader from "./loader/CareerLoader"
import Skeleton from "react-loading-skeleton"
import CareerCard from "./CareerCard"
import queryString from 'query-string'
import filterObserver from "../observer/FilterService"
import { objToQueryString } from '../plugins/url-utils'

const renderLoader = () => <Skeleton height={20} width={60} />;
const BtnLoadMore = lazy(() => import('./BtnLoadMore'));

export default class CareerList extends React.Component {
  constructor(props) {
    super(props);
    // console.log('Article from backend', this.props.careers)
    // console.log('params query string ', location.search)
    // console.log('params query string ', location)
    // console.log('query string parsed loaction ',queryString.parse(location.search))
    this.state = {
      loading: true,
      totalCareers: null,
      careers: null,
      error: false,
      nextPage: null 
    };
    // to allow the corresponding method to access 'this' variable
    this.onFilterUpdate = this.onFilterUpdate.bind(this);
    this.fetchCareers = this.fetchCareers.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentDidMount() {
    // subscribe to any filter changes
    filterObserver.attach(this.onFilterUpdate);
    var parsed = queryString.parse(location.search)
    this.fetchCareers(queryString.stringify(parsed), true);
  }

  componentWillUnmount() {
    // unsubscribe filter changes
    filterObserver.detach(this.onFilterUpdate);
  }

  onFilterUpdate(data) {
    let filter;
    this.setState({ loading: true });
    filter = { ...this.state.filter, ...data };
    // update the state
    this.setState({ filter });
    
    console.log("onFilterUpdated", filter);
    
    const params = objToQueryString(filter);
    this.renderLoading(2);
    this.fetchCareers(params, true);
  }

  fetchCareers(queryString, replaceExisting) {
    // console.log('params', queryString);
    // make `this` to be always callbale inside different instance
    const _this = this;
    // set loading to true while fetching the data
    this.setState({ loading: true}, () => {
      console.log("loading is", this.state.loading);
      const url = `${this.props.api_url}/api/v1/public/careers${queryString ? '?' + queryString : ''}`;
      // console.log('fetching careers:', url);
      fetch(url)
        .then(res => (res && res.status === 200 && res.ok ? res.json() : Promise.reject(res)))
        .then(data => {
          // console.log("retrieved", data);
          let careers;
          // replace or addition
          if (replaceExisting) {
            careers = data.careers.data;
          } else {
            // console.log('careers is', careers)
            careers = [..._this.state.careers, ...data.careers.data]
            // console.log('careers if replaceExisting false is ', careers)
          }
          // update data
          _this.setState({totalCareers: careers.length})
          setTimeout(() => {
            _this.setState({
              careers: careers,
              nextPage: data.pagination.next
            });
          }, 900);
        }).catch(res => {
          // error occurred
          console.warn('fetching error occurred', res);
          _this.setState({
            error: res
          });
        }).finally(() => {
          // request finished, set loading to false
          _this.setState({ loading: false })
        });
    });
  }

  generateContent(isLoading, careers, error, totalCareers) {
    if (error) {
      return `Something went wrong: ${error.message}`;
    } else if (careers) {
      if (careers.length) {
        return (
          <div className="row">
            { isLoading && this.renderLoading(totalCareers) }
            {!isLoading &&
              careers.map(career => (
                <CareerCard key={career.id} career={career.attributes} app_url={this.props.current_url} />
              ))
            }
          </div>
        );
      } else {
        return (<h3 className="text-center">Nothing to show</h3>);
      }
    } else {
      return this.renderLoading(totalCareers);
    }
  }

  // renderLoading(totalCareers){
  //   console.log('laoding here', totalCareers)
  //   return(
  //     <div className="row">
  //       {Array(totalCareers)
  //         .fill()
  //         .map((item, index) => (

  //           <div className="job-list" key={index}>
  //             <div className="body">
  //               <div className="content">
  //                 <h4>
  //                   <Skeleton height={15} width={`60%`} />
  //                 </h4>
  //                 <div className="info">
  //                   <span className="company">
  //                     <span className="office-location">
  //                       <Skeleton height={10} width={`50%`} />
  //                     </span>
                      
  //                   </span>
  //                   <p className="mt-2"> 
  //                     <Skeleton height={15} width={`90%`} />
  //                   </p>
  //                 </div>
  //               </div>
  //             </div>

  //             <div className="more">
  //               <div className="buttons">Apply Now</div>
  //               <p className="deadline"><Skeleton height={15} width={`50%`} /></p>
  //             </div>
  //           </div>
  //         ))
  //       }
  //     </div>
  //   )
  // }
  renderLoading(totalCareers){
    // console.log('laoding here', totalCareers)
    return(
      <div>
        {Array(totalCareers)
          .fill()
          .map((item, index) => (
              
            <CareerLoader key={index}/>
            
          ))
        }
      </div>
    )
  }

  handleLoadMore() {
    let params = {
      page: this.state.nextPage
    };
    console.log("Params is", params)
    params = objToQueryString(params);
    this.fetchCareers(params, false);
  }


  render(){
    const { loading, careers, error, nextPage, totalCareers } = this.state;
    // console.log("Article render", careers);
    return (
      <div>
        {this.generateContent(loading, careers, error, totalCareers)}
        {nextPage && 
          <div id="div_next_link">
            <div className="load-more text-center">
              <Suspense fallback={renderLoader()}>
                <BtnLoadMore onClick={this.handleLoadMore}></BtnLoadMore>
              </Suspense>
            </div>
          </div>
        }
      </div>
    )
  }
}
