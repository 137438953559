import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default class CareerCard extends React.Component {
  render() {
    // console.log(this.props.career)
    // const {scrollPosition} = this.props;
    return (
      <div className="job-list">
        <div className="body">
          <div className="content">
            <h4><a href={`careers/${this.props.career.slug}`}>{this.props.career.position}</a></h4>
            <div className="info">
              <span className="company">
                <span className="office-location">
                  <a href={`branch/${this.props.career.hospital_slug}`}>
                    <img width="20" src="https://hermina.s3-ap-southeast-1.amazonaws.com/assets/images/pin.svg"/>
                      {this.props.career.hospital_name}
                  </a>
                </span>
                <span className="office-location">
                  <a href={`careers/${this.props.career.slug}`}>
                    <img width="20" src="https://hermina.s3-ap-southeast-1.amazonaws.com/assets/images/graduate.svg" className="mr-1"/>
                    {this.props.career.degree}
                  </a>
                </span>
              </span>
              <p className="mt-2"> 
                {this.props.career.short_desc.replace(/&nbsp;/g, ' ')}
              </p>
            </div>
          </div>
        </div>
        <div className="more">
          <div className="buttons"><a className="button" href={this.props.career.link} target='_blank'>Apply Now</a></div>
          <p className="deadline">Deadline: {this.props.career.due_date}</p>
        </div>
      </div>
    );
  }
}
